import * as React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';

import TextareaInput from '@common/components/form/inputs/textarea';
import FileCropInput from '@common/components/form/inputs/file-crop';
import * as AlertService from '../../../../common/services/alert';
import { Row, Group, TextInput } from '../../../../common/components/form';
import Spinner from '../../../../common/components/spinner';
import { Button } from '../../../../common/components/button';
import FilePreview from '../../../../common/components/file-preview/image';
import updateConversation from '../../actions/update-conversation';

class ConversationForm extends React.Component {
  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static props;

  async handleSubmit(values, dispatch) {
    const { conversation, t } = this.props;
    // eslint-disable-next-line camelcase
    const { name, description, group_img } = values;

    try {
      const payload = {};
      if (name !== conversation.name) payload.name = name;
      if (description !== conversation.description) payload.description = description;
      // eslint-disable-next-line camelcase
      if (group_img && group_img.path !== conversation.group_img) payload.group_img = group_img;

      await dispatch(updateConversation(conversation.id, payload));
    } catch (response) {
      AlertService.forStatus(response.status_code, {
        warning: t('chat:conversation_form_warning_edit_name'),
        error: t('chat:conversation_form_error_edit_name'),
      });
    }
  }

  render() {
    const {
      conversation, handleSubmit, submitting, dirty, t,
    } = this.props;

    return (
      <form className="Form">
        <div className="ConversationForm__Image">
          <div
            className="ConversationForm__Image__Container"
            style={{ backgroundImage: `url(${conversation.group_img || '/static/images/group_chat_placeholder.png'}` }}
          >
            <Field
              name="group_img"
              component={({ input: { value, onChange } }) => (value
                ? <FilePreview size={550} file={value} onRemove={() => onChange(null)} />
                : (
                  <FileCropInput name="group_img">
                    <Button type="white"><Trans i18nKey="chat:conversation_form_upload_new_image" /></Button>
                  </FileCropInput>
                )
              )}
            />
          </div>
        </div>
        <Row>
          <Group>
            <TextInput name="name" placeholder={t('chat:conversation_form_name_input_placeholder')} />
          </Group>
          {submitting
            ? <Spinner />
            : dirty && (
              <a className="ConversationForm__TitleForm__Save" onClick={handleSubmit(this.handleSubmit)}>
                <Trans i18nKey="chat:conversation_form_save" />
              </a>
            )}
        </Row>
        <Row>
          <Group>
            <TextareaInput
              name="description"
              placeholder={t('chat:conversation_form_description_input_placeholder')}
            />
          </Group>
        </Row>
      </form>
    );
  }
}

export default withTranslation()(reduxForm({
  initialValues: {
    name: '',
    description: '',
    group_img: null,
  },
})(ConversationForm));
