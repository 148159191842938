import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SearchableList from '@common/components/list/search';
import ImageItem from '@common/components/image-item';
import Section from './section';

import { useAppSelector } from '@common/hooks';
import fetchNetworkUsers from '../../../network/actions/fetch-users';
import { fetchUsers as fetchOrganisationUsers } from '../../../organisation/actions';

import { LoggedUser, User } from '@common/types/objects';

export type ParticipantsListComponentProps = {
  loggedUser: LoggedUser;
  participants: Array<User>;
  isGroup: boolean;
  onToggle: (user: User, selected: boolean) => void;
  onSelect: (user: User) => void;
  isDisabled: (user: User) => boolean;
  onToggleGroup: () => void;
  fetchNetworkUsers: (offset?: number, filter?: { query?: string }, limit?: number) => Promise<Object>;
  fetchOrganisationUsers: (offset?: number, filter?: { query?: string }, limit?: number) => Promise<Object>;
  delay?: number;
};

const getFetchParams = (filter: Record<string, any>) => {
  return {
    ...filter,
    sortBy: 'first_name',
    canChat: true,
  };
};

const ParticipantsListComponent = ({
  loggedUser,
  participants,
  isGroup,
  delay,
  onToggle,
  onSelect,
  onToggleGroup,
  isDisabled,
}: ParticipantsListComponentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  const selectedNetworkId = useAppSelector((state) => state.network.selected);

  const inAdminSide = url.startsWith('/admin');
  const showMyCommunity = !inAdminSide && selectedNetworkId;

  const fetchUsersFromSelectedNetwork = async (filter = {}, offset = 0) => {
    return dispatch(fetchNetworkUsers(offset, getFetchParams(filter), 6));
  };

  const fetchUsersFromOrganisation = async (filter = {}, offset = 0) => {
    return dispatch(fetchOrganisationUsers(offset, getFetchParams(filter), 6));
  };

  return (
    <SearchableList
      key="overview"
      containerClassname="ParticipantsList"
      data={{
        onFetch: async (_: any, filter: Record<string, any>) => {
          // Prevent data from being loaded before modal animation is finished (looks weird)
          if (delay) await new Promise((resolve) => setTimeout(resolve, 250));

          let data: any[] = [];
          if (showMyCommunity) {
            const response = await fetchUsersFromSelectedNetwork(filter);
            data = [...data, {
              label: t('chat:participants_list_my_network'),
              response,
              onFetch: (newOffset: number) => fetchUsersFromSelectedNetwork(filter, newOffset),
            }];
          }

          // We always display either Organisation users or Other community users.
          const organisationFilter = {
            ...(filter || {}),
            excludeCurrentNetwork: !inAdminSide
          };
          const response: any = await fetchUsersFromOrganisation(organisationFilter);

          // Avoid displaying the section if no users are found in the initial fetch to prevent rendering an empty 'Other communities' section.
          if (response.data.length) {
            data = [...data, {
              label: !inAdminSide ?
                t('chat:participants_list_other_networks') :
                t('chat:participants_list_organisation'),
              response,
              onFetch: (newOffset: number) => fetchUsersFromOrganisation(organisationFilter, newOffset),
            }];
          }
          return {
            data,
            meta: {
              pagination: {},
            },
          };
        },
      }}
      header={!isGroup && (
        <ImageItem
          className="NewConversationModal__Item"
          icon="group_add__filled"
          name={t('chat:participants_list_create_group')}
          onClick={onToggleGroup}
        />
      )}
      renderRow={Section}
      rowProps={{
        loggedUser,
        participants,
        isGroup,
        isDisabled,
        onToggle: isGroup && onToggle,
        onSelect: !isGroup && onSelect,
      }}
      placeholder={t('chat:participants_list_placeholder')}
    />
  );
};

export default ParticipantsListComponent;
