import React, { useEffect, useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import FileInput from '@common/components/form/inputs/file';
import GifInput from '@common/components/form/inputs/gif/gif';
import Permission from '@common/components/permission';
import TextareaInput from '@common/components/form/inputs/textarea';
import Tooltip from '@common/components/tooltip';
import Icon from '@common/components/icon';
import { Button } from '@common/components/button';
import { Messages, ErrorType } from '@common/components/form/message';
import FilePreview from '@common/components/file-preview/file';
import ImagePreview from '@common/components/file-preview/image';
import { Row } from '@common/components/form';

import { ALL_ACCEPT } from '@common/components/form/inputs/file/utils';
import { combineClassNames } from '@common/utils/combineClassNames';
import { useFormValues } from '@common/hooks/form-hook';
import { useAppSelector } from '@common/hooks';
import validate from './validate';
import { canPreviewAttachment } from '@common/utils/file';
import postMessage from '../../actions/post-message';
import * as messagesReducer from '../../reducers/messages';

import { Attachment, FileInProgress, FileInputValue } from '@common/types/objects';
import { PrivateMessage } from '@modules/chat/types/objects';
import { EComponentTypes } from '@common/definitions';
import { useFileFromClipboard } from '@common/components/form/inputs/file/hooks';

type OwnProps = {
  disabled?: boolean;
  conversationId: string;
  inputRef?: (input: HTMLInputElement) => void;
};

export type FormData = {
  text: string;
  attachments: FileInputValue[];
  message_id: string | null;
  gif?: Attachment;
};

type Props = InjectedFormProps<FormData, OwnProps, boolean> & OwnProps;

const maxAmountOfFiles = 10;

const PostMessage = ({
  form, change, submitting, disabled, invalid, conversationId, reset, handleSubmit, inputRef,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formValues = useFormValues<FormData>(form);
  const replyTo: PrivateMessage = useAppSelector((state) => messagesReducer.findById(state, formValues.message_id));
  const [rejectedFiles, setRejectedFiles] = useState<ErrorType[]>([]);
  const input: HTMLInputElement | null = document.querySelector('.PostPrivateMessage__Input textarea');

  const onAttachmentUpdate = (file?: FileInputValue, index?: number) => change(`attachments[${index}]`, file);
  const { onChange: handleAddImageFromClipboard } = useFileFromClipboard(
    formValues.attachments, maxAmountOfFiles, onAttachmentUpdate, setRejectedFiles,
  );

  useEffect(() => {
    if (inputRef && input) inputRef(input);
  }, [input, inputRef]);

  const submitForm = async (values: FormData) => {
    await dispatch(postMessage(conversationId, values));
    reset();
    input?.focus();
  };

  const handleRemoveAttachment = (index: number) => {
    const attachment = formValues.attachments[index];
    if (attachment) {
      if (attachment?.processing) (attachment as FileInProgress).cancelPromise?.();
      formValues.attachments.splice(index, 1);
      change('attachments', formValues.attachments);
    }
  };

  let images: FileInputValue[] = [];
  let documents: FileInputValue[] = [];
  formValues.attachments.forEach((attachment, index) => {
    if (canPreviewAttachment(attachment)) {
      images = [...images, { ...attachment, index }];
    } else {
      documents = [...documents, { ...attachment, index }];
    }
  });

  return (
    <>
      {formValues.message_id && replyTo && (
        <div className="PostPrivateMessage__Reply">
          <div className="PostPrivateMessage__Reply__Quote">
            <b>{replyTo.user.full_name}</b>
            {replyTo.text}
          </div>
          <Icon type="close" onClick={() => change('message_id', null)} />
        </div>
      )}
      <form
        onSubmit={handleSubmit(submitForm)}
        className={combineClassNames('Form PostPrivateMessage fs-mask', {
          'PostPrivateMessage--invalid': !!rejectedFiles.length,
        })}
      >
        {rejectedFiles?.length > 0 && <Messages errors={rejectedFiles} />}
        {formValues.gif && (
          <div className="PostPrivateMessage__Attachments">
            <ImagePreview
              key={formValues.gif.id}
              file={formValues.gif}
              size={70}
              onRemove={() => change('gif', null)}
            />
          </div>
        )}
        {images.length > 0 && (
          <div className="PostPrivateMessage__Attachments">
            {images.map((attachment) => (
              <ImagePreview
                key={attachment.id || attachment.file_name}
                file={attachment}
                size={70}
                onRemove={() => handleRemoveAttachment(attachment.index!)}
              />
            ))}
            <FileInput
              multiple={maxAmountOfFiles}
              name="attachments"
              accept="image/*,video/*"
              onError={setRejectedFiles}
            >
              <Icon type="add" />
            </FileInput>
          </div>
        )}
        {documents.length > 0 && (
          <div className="PostPrivateMessage__Documents">
            {documents.map((attachment) => (
              <FilePreview
                key={attachment.id || attachment.file_name}
                // @ts-expect-error
                file={attachment}
                onRemove={() => handleRemoveAttachment(attachment.index!)}
              />
            ))}
          </div>
        )}
        <Row className="PostPrivateMessage__Actions">
          <FileInput
            multiple={maxAmountOfFiles}
            disabled={!!formValues.gif}
            name="attachments"
            accept="image/*, video/*"
            onError={setRejectedFiles}
          >
            <Tooltip title={t('chat:post_message_attachment_photo_video')}>
              <Icon type="image__filled" className="PostPrivateMessage__Action" />
            </Tooltip>
          </FileInput>
          <FileInput
            multiple={maxAmountOfFiles}
            disabled={!!formValues.gif}
            name="attachments"
            accept={ALL_ACCEPT}
            onError={setRejectedFiles}
          >
            <Tooltip title={t('chat:post_message_attachment_document')}>
              <Icon type="document__filled" className="PostPrivateMessage__Action" />
            </Tooltip>
          </FileInput>
          <Permission component={EComponentTypes.GIPHY}>
            <GifInput name="gif" disabled={!!formValues.gif || formValues.attachments.length > 0}>
              <Icon type="gif" className="PostPrivateMessage__Action" />
            </GifInput>
          </Permission>
          <div className="PostPrivateMessage__Input">
            <TextareaInput
              enableEmojis
              disableValidationFeedback
              name="text"
              placeholder={t('chat:post_message_form_text_placeholder')}
              onEnter={handleSubmit(submitForm)}
              disabled={submitting}
              onPaste={handleAddImageFromClipboard}
            />
          </div>
          <Button
            type="primary"
            buttonType="submit"
            icon="send"
            isLoading={submitting}
            disabled={disabled || invalid}
          />
        </Row>
      </form>
    </>
  );
};

export default reduxForm<FormData, OwnProps, boolean>({
  form: 'post-message',
  destroyOnUnmount: false,
  initialValues: {
    text: '',
    attachments: [],
    message_id: null,
  },
  validate,
})(PostMessage);
